import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6', // Example of a blue shade
    },
    secondary: {
      main: '#19857b', // Example of a teal shade
    },
    error: {
      main: '#ff1744',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
      letterSpacing: '0.0075em',
    },
    // You can add more typography styles here
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          // Style for the root element
          borderColor: 'green', // Example: change border color
          height: '3.6em', // Fixed height for the select
          // Adding padding to root might affect the overall height and appearance
        },
        select: {
          // Style for the select element itself
          // color: 'blue', // Example: change text color
          padding: '0 1em', // Inset the text slightly with horizontal padding
          lineHeight: '5em', // Center the text vertically
          // '&:focus': {
          //   backgroundColor: 'lightgray', // Background color when focused
          // },
        },
        icon: {
          // Style for the dropdown icon
          // color: 'red', // Example: change icon color
          // Ensure the icon is aligned with the text
          top: 'calc(50% - .5em)', // Adjust this value as necessary to center the icon
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '3.6em', // Set the desired height
          // ... (any other root styles you need)
        },
        input: {
          padding: '0 14px', // Adjust the left padding as needed
          // If you've set a large height, you may also need to adjust the line height to center the text vertically
          lineHeight: '2.5em', // Example line height - adjust as needed
        },
        notchedOutline: {
          // Styles for the notched outline
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          paddingLeft: '14px', // Adjust the left padding as needed for the standard variant
          // Line height for the standard variant if needed
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          paddingLeft: '14px', // Adjust the left padding as needed for the filled variant
          // Line height for the filled variant if needed
        },
      },
    },
  }  
});

export default theme;
