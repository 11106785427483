import React, { useRef, useEffect } from 'react';
import styles from './Home.module.css';

import SignIn from '../utils/sign-in.component';

function Home(props) {

  const tiltRef = useRef(null);

  useEffect(() => {
    const el = tiltRef.current;
  
    const handleMove = (e) => {
      const rect = el.getBoundingClientRect();
      const xVal = e.clientX - rect.left; // get mouse x position within element
      const yVal = e.clientY - rect.top; // get mouse y position within element
      const height = el.clientHeight;
      const width = el.clientWidth;
      const yRotation = 6 * ((xVal - width / 2) / width);
      const xRotation = -6 * ((yVal - height / 2) / height);
      const transformString = `perspective(500px) scale(1.1) rotateX(${xRotation}deg) rotateY(${yRotation}deg)`;
      el.style.transform = transformString;
    };
  
    const handleMouseOut = () => {
      el.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)';
    };
  
    const handleMouseDown = () => {
      el.style.transform = 'perspective(500px) scale(1.2) rotateX(0) rotateY(0)';
    };
  
    const handleMouseUp = () => {
      el.style.transform = 'perspective(500px) scale(1.2) rotateX(0) rotateY(0)';
    };
  
    el.addEventListener('mousemove', handleMove);
    el.addEventListener('mouseout', handleMouseOut);
    el.addEventListener('mousedown', handleMouseDown);
    el.addEventListener('mouseup', handleMouseUp);
  
    return () => {
      el.removeEventListener('mousemove', handleMove);
      el.removeEventListener('mouseout', handleMouseOut);
      el.removeEventListener('mousedown', handleMouseDown);
      el.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);
  


  return (
    <div className='flex flex-col items-center w-full h-full max-w-screen overflow-x-hidden bg-backgroundPrimary '>

      <div className='relative flex flex-col items-center justify-center w-screen min-h-screen max-w-screen overflow-hidden'>

        <div class='flex items-center justify-between w-[1200px] max-w-[90vw] mx-auto mt-8'>
          <div class='text-4xl font-bold text-black font-staatliches tracking-wide'>Playset</div>
          <div class='flex-grow flex justify-center'>
            <div class='flex'>
              {/* <div class='text-xl font-bold text-black mx-4 font-poppins'>Features</div> */}
              {/* <div class='text-xl font-bold text-black mx-4 font-poppins'>Pricing</div> */}
            </div>
          </div>
          {/* <SignIn /> */}
          {/* <div class='text-lg font-bold text-white bg-black px-6 py-2 ml-4 rounded-full font-poppins hover:scale-105 cursor-pointer'>Log In</div> */}
        </div>



        <div className='flex flex-col items-center w-[1200px] max-w-[90vw] mt-20'>
          <div className='relative font-staatliches tracking-wide text-3xl md:text-[60pt] text-center font-bold text-black leading-[1.1]'>
            <span className={styles.DynamicText}>DYNAMIC</span> APPS FOR Y
            <span className='inline-block align-text-top text-[48pt]' style={{ transform: 'translateY(15px)' }}>⚽️</span>UR TEAM.<br />
            WITH <span className={styles.GradientText}>NO CODE!</span>
          </div>

          {/* <div className='font-opensans text-xl font-medium text-black mt-8 mx-4'>
            Create premium iOS and Android apps for your fans
          </div> */}
          <div className='font-opensans text-xl font-medium text-black mt-8 mx-4'>
            Premium iOS and Android football apps, with effortless no-code simplicity and speed
          </div>
          {/* <div className='font-poppins text-lg font-bold text-white bg-black px-6 py-2 rounded-full mt-8 hover:scale-105 cursor-pointer'>Book a demo &rarr;</div> */}
          <a href="mailto:playsetapp@gmail.com">
            <div className='font-poppins text-lg font-bold text-white bg-black px-6 py-2 rounded-full mt-8 hover:scale-105 cursor-pointer'>Book a demo &rarr;</div>
          </a>





          {/* <img src={`${process.env.PUBLIC_URL}/editorScreenshot.png`} className='w-[1200px] mx-[120px] my-20 border border-navy border-dashed rounded-2xl' /> */}

          {/* <iframe className=' my-20 w-[1200px] h-[675px] rounded-3xl border border-black border-dashed' src="https://www.youtube.com/embed/6lzCHxnHlEE?si=uG-uPROhWkDKsdyf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}

        </div>

        <div className='relative'>
          <div className='gradientBlob absolute inset-0 m-auto'></div>
          <img
            ref={tiltRef}
            src={`${process.env.PUBLIC_URL}/editorScreenshot.png`}
            className='tilt-image w-[1200px] mx-[120px] my-20 border border-navy border-dashed rounded-2xl relative z-10'
          />
        </div>


        <div className='flex flex-col w-[1200px] max-w-[90vw] mt-20'>
          <div className='font-staatliches tracking-wide text-3xl md:text-[60pt] font-bold text-black leading-[1.1] mb-12'>
            Playset creates premium quality fan experiences, <span className='gradient-text'>faster and cheaper.</span>
          </div>

          <div className='font-staatliches tracking-wide text-3xl md:text-[60pt] font-bold text-black leading-[1.1] mt-20 mb-4'>
            FEATURES
          </div>

          <div className='font-opensans text-xl font-medium text-black mb-2'>Give your fans everything they expect.</div>
          <div className='font-opensans text-xl font-medium text-black mb-2'>
            📰 News&nbsp;&nbsp;
            ⚽️ Fixtures&nbsp;&nbsp;
            🏆 Results&nbsp;&nbsp;
            🥇 Table&nbsp;&nbsp;
            ⚡️ Match Centre&nbsp;&nbsp;
          </div>
        </div>

        <div class="relative flex overflow-x-hidden w-screen mt-12 mb-12">
          <div class="flex flex-row animate-marquee whitespace-nowrap">
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/home.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/article.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/news.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/fixtures.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/stats.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/table.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/events.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
          </div>

          <div class="flex flex-row absolute animate-marquee2 whitespace-nowrap">
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/home.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/article.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/news.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/fixtures.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/stats.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/table.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
            <span><img src={`${process.env.PUBLIC_URL}/screenshots/events.png`} className='h-[24rem] mx-[120px] border border-navy border-dashed rounded-2xl' /></span>
          </div>

        </div>



        <div className='flex flex-col  w-[1200px] max-w-[90vw] my-20'>

          <div className='font-staatliches tracking-wide text-3xl md:text-[60pt] font-bold text-black leading-[1.1] mb-4'>
            WHY PLAYSET?
          </div>
          <div className='font-opensans text-xl font-medium text-black mb-2'>
            Traditional studios are slow, expensive, and may design something not to your taste.
          </div>
          <div className='font-opensans text-xl font-medium text-black mb-2'>
            Playset has a templated approach, giving you instant control over the look and feel of your app.
          </div>
          <div className='font-opensans text-xl font-medium text-black mb-2'>
            This also makes it significantly faster and more affordable than traditional dev studio!
          </div>
          <div className='font-opensans text-xl font-medium text-black mb-6'>
            Here's how it works...
          </div>

          <div class="w-full flex flex-col relative overflow-hidden mb-12">
            <div class="h-2 w-[960px] animated-gradient-bg transform translate-y-[48px]"></div>
            <div class="w-[80vw] max-w-[1200px] flex flex-row items-start">

              <div class="w-[320px] flex flex-col z-10">
                <div class="w-24 h-24 bg-white rounded-full flex items-center justify-center border-2 border-black">
                  <h1 class="text-5xl font-medium text-black">⚽️</h1>
                </div>
                <h1 class="font-staatliches tracking-wide text-black text-3xl font-bold mt-3 mb-1.5">Team</h1>
                <p class="font-opensans text-lg text-black font-medium">Choose your team,<br /> from 20,000+ worldwide</p>
              </div>

              <div class="w-[320px] flex flex-col z-10">
                <div class="w-24 h-24 bg-white rounded-full flex items-center justify-center border-2 border-black">
                  <h1 class="text-5xl font-medium text-black">🎨</h1>
                </div>
                <h1 class="font-staatliches tracking-wide text-black text-3xl font-bold mt-3 mb-1.5">Customise</h1>
                <p class="font-opensans text-lg text-black font-medium">Design your app with a<br /> simple no code editor</p>
              </div>

              <div class="w-[320px] flex flex-col z-10">
                <div class="w-24 h-24 bg-white rounded-full flex items-center justify-center border-2 border-black">
                  <h1 class="text-5xl font-medium text-black">🪄</h1>
                </div>
                <h1 class="font-staatliches tracking-wide text-black text-3xl font-bold mt-3 mb-1.5">Magic</h1>
                <p class="font-opensans text-lg text-black font-medium">Playset connects your app<br /> to live data feeds</p>
              </div>

              <div class="w-[240px] flex flex-col z-10">
                <div class="w-24 h-24 bg-white rounded-full flex items-center justify-center border-2 border-black">
                  <h1 class="text-5xl font-medium text-black">🚀</h1>
                </div>
                <h1 class="font-staatliches tracking-wide text-black text-3xl font-bold mt-3 mb-1.5">Launch</h1>
                <p class="font-opensans text-lg text-black font-medium">Launch on the Apple<br /> and Android app stores</p>
              </div>

            </div>
          </div>



          <div className='font-staatliches tracking-wide text-3xl md:text-[60pt] font-bold text-black leading-[1.1] mt-20'>
            PRICING
          </div>

          <div className='flex flex-row justify-between mt-4'>
            <div className='flex flex-col w-[48%] bg-white p-6 rounded-2xl'>
              <div className='font-staatliches tracking-wide text-4xl'>STANDARD</div>
              <div className='font-opensans text-lg font-medium'>
                <div className='mt-4'>✔&nbsp; iOS and Android apps</div>
                <div className='mt-4'>✔&nbsp; All standard features</div>
                <div className='mt-4'>&nbsp;</div>

                <a href="mailto:playsetapp@gmail.com">
                  <div className='mt-4 bg-black font-staatliches tracking-wide text-white rounded-xl w-max px-4 py-2'>get a quote</div>
                </a>
              </div>
            </div>

            <div className='flex flex-col w-[48%] bg-black text-white p-6 rounded-2xl'>
              <div className='font-staatliches tracking-wide text-4xl'>CUSTOM</div>
              <div className='font-opensans text-lg font-medium'>
                <div className='mt-4'>✔&nbsp; Everything in Standard</div>
                <div className='mt-4'>✔&nbsp; Custom features developed especially for you</div>
                <div className='mt-4'>✔&nbsp; Custom store and ticketing implementation</div>

                <a href="mailto:playsetapp@gmail.com">
                  <div className='mt-4 bg-white font-staatliches tracking-wide text-black rounded-xl w-max px-4 py-2'>get a quote</div>
                </a>
              </div>
            </div>
          </div>

        </div>

        {/* <div className='font-poppins text-lg font-bold text-white bg-black px-6 py-2 rounded-full my-20 hover:scale-105 cursor-pointer'>Book a demo &rarr;</div> */}




      </div>



      <div className='flex items-center font-opensans text-black justify-center w-screen bg-[#fefefe] py-20'>
        &copy; Playset 2024
      </div>

    </div >
  );
}

export default Home;
