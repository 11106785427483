import './App.css';
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; // Import Navigate
import { ConfigProvider } from './Editor/ConfigContext';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

import Home from './Home/Home';
import Editor from './Editor/Editor';
import Dashboard from './Dashboard/Dashboard';
import News from './News/News';
import NewsEditor from './NewsEditor/NewsEditor';

import { auth } from './utils/firebase.utils';
import { onAuthStateChanged } from 'firebase/auth';

function App() {
  const [user, setUser] = useState(null);
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(localStorage.getItem('onboardingComplete') === 'true');

  const [playsetUser, setPlaysetUser] = useState(localStorage.getItem('playsetUser'));
  const checkIfUserExists = async (uid) => {
    const url = "https://lpzoi9avqb.execute-api.eu-north-1.amazonaws.com/check-if-user-exists-or-create-user";
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);

      return data; // Return the data for further processing
    } catch (error) {
      console.error("There was an error checking the user", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        localStorage.setItem('isLoggedIn', 'true');
        setUser(firebaseUser);

        try {
          const lambdaResponse = await checkIfUserExists(firebaseUser.uid);
          // Assuming lambdaResponse.user contains the user object you're interested in
          if (lambdaResponse && lambdaResponse.user) {
            const userString = JSON.stringify(lambdaResponse.user);
            localStorage.setItem('playsetUser', userString); // Correctly saving to localStorage
            setPlaysetUser(lambdaResponse.user); // Saving the object for immediate use, if needed
          }
        } catch (error) {
          console.error("There was an error fetching the user data:", error);
        }
      } else {
        localStorage.removeItem('isLoggedIn');
        setUser(null);
        localStorage.removeItem('playsetUser'); // Clear playsetUser if not logged in
      }
    });

    return () => unsubscribe();
  }, []);



  // Listen for changes in onboardingComplete status
  useEffect(() => {
    const onboardingStatus = localStorage.getItem('onboardingComplete') === 'true';
    setIsOnboardingComplete(onboardingStatus);
  }, [user]); // Re-run this effect when `user` changes. Adjust the dependency array as needed.

  // Check if user is null and render Navigate component to redirect to login or home
  const renderProtectedRoute = (Component) => {

    // localStorage.setItem('onboardingComplete', 'false');

    if (isLoggedIn) {
      if (isOnboardingComplete) {
        return Component;
      } else {
        return <Navigate to="/onboarding" />;
      }
    } else {
      return <Navigate to="/" />;
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider user={user}>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/dashboard" element={renderProtectedRoute(<Dashboard user={user} playsetUser={playsetUser} />)} />
          <Route path="/editor" element={renderProtectedRoute(<Editor user={user} />)} />
          <Route path="/news" element={renderProtectedRoute(<News user={user} />)} />
          <Route path="/newseditor" element={renderProtectedRoute(<NewsEditor user={user} />)} /> */}
        </Routes>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
