// ConfigContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

export const ConfigContext = createContext();

export const ConfigProvider = ({ user, children }) => {

  const [teamName, setTeamName] = useState('Team Name');
  const [teamLogo, setTeamLogo] = useState('');

  const [appName, setAppName] = useState('App Name');
  const [appIcon, setAppIcon] = useState('');

  const [appStyle, setAppStyle] = useState('Modern');

  const [primaryColour, setPrimaryColour] = useState('#191f2c');
  const [secondaryColour, setSecondaryColour] = useState('#c3ed2e');

  const [headerText, setHeaderText] = useState('Header Text');
  const [headerIcon, setHeaderIcon] = useState('');
  const [displaySponsorLogo, setDisplaySponsorLogo] = useState(false);
  const [sponsorLogo, setSponsorLogo] = useState('');
  const [sponsorLinkBool, setSponsorLinkBool] = useState(false);
  const [sponsorLinkUrl, setSponsorLinkUrl] = useState('');

  const [displayTabBarText, setDisplayTabBarText] = useState(true);
  const [tabBarHome, setTabBarHome] = useState('Home');
  const [tabBarNews, setTabBarNews] = useState('News');
  const [tabBarFixtures, setTabBarFixtures] = useState('Fixtures');
  const [tabBarTable, setTabBarTable] = useState('Table');
  const [tabBarStore, setTabBarStore] = useState('Store');

  const [includeSocialStories, setIncludeSocialStories] = useState(true);
  const [includeUpcomingFixtures, setIncludeUpcomingFixtures] = useState(true);
  const [includeLatestNewsStory, setIncludeLatestNewsStory] = useState(true);
  const [includeAbridgedLeagueTable, setIncludeAbridgedLeagueTable] = useState(true);

  const [latestStoryLargePreview, setLatestStoryLargePreview] = useState(true);

  const [displayTablePositionIndicators, setDisplayTablePositionIndicators] = useState(true);
  const [displayTableRank, setDisplayTableRank] = useState(true);
  const [displayTableWins, setDisplayTableWins] = useState(false);
  const [displayTableLosses, setDisplayTableLosses] = useState(false);
  const [displayTableDraws, setDisplayTableDraws] = useState(false);
  const [displayTablePlayed, setDisplayTablePlayed] = useState(true);
  const [displayTableGoalDifference, setDisplayTableGoalDifference] = useState(true);
  const [displayTablePoints, setDisplayTablePoints] = useState(true);

  const [displayMatchCentreStatsPossession, setDisplayMatchCentreStatsPossession] = useState(true);
  const [displayMatchCentreStatsShotsOnGoal, setDisplayMatchCentreStatsShotsOnGoal] = useState(true);
  const [displayMatchCentreStatsTotalShots, setDisplayMatchCentreStatsTotalShots] = useState(true);
  const [displayMatchCentreStatsCorners, setDisplayMatchCentreStatsCorners] = useState(true);
  const [displayMatchCentreStatsOffsides, setDisplayMatchCentreStatsOffsides] = useState(true);
  const [displayMatchCentreStatsFouls, setDisplayMatchCentreStatsFouls] = useState(true);
  const [displayMatchCentreStatsYellowCards, setDisplayMatchCentreStatsYellowCards] = useState(true);
  const [displayMatchCentreStatsRedCards, setDisplayMatchCentreStatsRedCards] = useState(true);
  const [includeMatchCentreLineups, setIncludeMatchCentreLineups] = useState(true);

  return (
    <ConfigContext.Provider value={{
      teamName, setTeamName,
      teamLogo, setTeamLogo,

      appName, setAppName,
      appIcon, setAppIcon,

      appStyle, setAppStyle,

      primaryColour, setPrimaryColour,
      secondaryColour, setSecondaryColour,

      headerText, setHeaderText,
      headerIcon, setHeaderIcon,
      displaySponsorLogo, setDisplaySponsorLogo,
      sponsorLogo, setSponsorLogo,
      sponsorLinkBool, setSponsorLinkBool,
      sponsorLinkUrl, setSponsorLinkUrl,

      displayTabBarText, setDisplayTabBarText,
      tabBarHome, setTabBarHome,
      tabBarNews, setTabBarNews,
      tabBarFixtures, setTabBarFixtures,
      tabBarTable, setTabBarTable,
      tabBarStore, setTabBarStore,

      includeSocialStories, setIncludeSocialStories,
      includeUpcomingFixtures, setIncludeUpcomingFixtures,
      includeLatestNewsStory, setIncludeLatestNewsStory,
      includeAbridgedLeagueTable, setIncludeAbridgedLeagueTable,

      latestStoryLargePreview, setLatestStoryLargePreview,

      displayTablePositionIndicators, setDisplayTablePositionIndicators,
      displayTableRank, setDisplayTableRank,
      displayTableWins, setDisplayTableWins,
      displayTableLosses, setDisplayTableLosses,
      displayTableDraws, setDisplayTableDraws,
      displayTablePlayed, setDisplayTablePlayed,
      displayTableGoalDifference, setDisplayTableGoalDifference,
      displayTablePoints, setDisplayTablePoints,

      displayMatchCentreStatsPossession, setDisplayMatchCentreStatsPossession,
      displayMatchCentreStatsShotsOnGoal, setDisplayMatchCentreStatsShotsOnGoal,
      displayMatchCentreStatsTotalShots, setDisplayMatchCentreStatsTotalShots,
      displayMatchCentreStatsCorners, setDisplayMatchCentreStatsCorners,
      displayMatchCentreStatsOffsides, setDisplayMatchCentreStatsOffsides,
      displayMatchCentreStatsFouls, setDisplayMatchCentreStatsFouls,
      displayMatchCentreStatsYellowCards, setDisplayMatchCentreStatsYellowCards,
      displayMatchCentreStatsRedCards, setDisplayMatchCentreStatsRedCards,
      includeMatchCentreLineups, setIncludeMatchCentreLineups,
    }}>
      {children}
    </ConfigContext.Provider>
  );
};
